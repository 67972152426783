import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class ConstantQueryService {

  HoldingSummaryQuery: any;
  InvestmentManagerQuery: any;
  AssetSummaryQuery: any;
  IconSetQuery: any;
  loginLandingPage: any;
  landingPageByIDQuery: any;
  headerQuery: any;
  termsConditionsQuery: any;
  sessionTimeOutQuery: any;
  pageNotFoundQuery: any;
  externalRedirectIconQuery: any;
  seriesResourceCollectionQuery: any;
  financialValues: any;

  constructor() {
    this.HoldingSummaryQuery = `query pageHoldingCollectionQuery($masterId: Int!, $previewFlag: Boolean!) {
      pageHoldingCollection(preview: $previewFlag, where: { masterId: $masterId }, limit: 1) {
        items {
          sys {
            id
          }
          masterId
          name
          ticker
          summary {
            json
          }
        }
      }
    }`;

    this.InvestmentManagerQuery = `query investmentManagerCollectionQuery($masterId: Int!, $previewFlag: Boolean!) {
      investmentManagerCollection(preview: $previewFlag, where: { masterId: $masterId }, limit: 1) {
        items {
          masterId
          name
          ticker
          logo {
            url
          }
        }
      }
    }`;

    this.AssetSummaryQuery = `query assetDetailsCollectionQuery($masterId: Int!, $previewFlag: Boolean!){
      assetDetailsCollection(preview: $previewFlag, where: { masterId: $masterId }, limit: 1) {
        items {
          masterId
          name
          assetUrl
          logo {
            url
          }
          summary {
            json
          }
          photosCollection {
            items {
              url
            }
          }
          map {
            lat
            lon
          }
        }
      }
    }`

    this.IconSetQuery = `query iconCollectionQuery($previewFlag: Boolean!) {
      iconCollection(preview: $previewFlag) {
        items {
          key
          iconImage {
            url
          }
        }
      }
    }`;

    this.loginLandingPage = gql` query landingPageEntryQuery($previewFlag:Boolean!) {
      landingPage(id: "21IXOLC0LZfm9Hr1vfZTyS", preview: $previewFlag) {
        sys {
          id
        }
        internalName
        backgroundImage {
          url
        }
        clientScopeLogo {
          url
        }
        gcmLogo {
          url
        }
        gcmGrosvenorWebPortalUserAgreement {
          url
        }
        sectionsCollection {
          items {
            ...resourceSetFields
            ...brandingFields
            ... on RichTextContents {
              contents {
                json
              }
            }
          }
        }
      }
    }
    fragment resourceSetFields on ResourceSet {
      name
      resourcesCollection {
        items {
          ... on Resource {
            key
            value
          }
        }
      }
    }
    fragment brandingFields on Branding {
      internalName
      heading
      copyright
      logo {
        url
      }
      content
      {
        json
      }
    }`;

    this.landingPageByIDQuery = `query landingPageEntryQuery($id: String!, $previewFlag: Boolean!) {
      landingPage(id: $id, preview: $previewFlag) {
        internalName
        backgroundImage {
          url
        }
        sectionsCollection {
          items {
            ... on ResourceSet {
              name
              resourcesCollection {
                items {
                  ... on Resource {
                    internalName
                    key
                    value
                  }
                }
              }
            }
          }
        }
      }
    }`;

    this.headerQuery = `query headerEntryQuery($previewFlag: Boolean!) {
      header(id: "24vTFpdgVim1axOOtPA9Oa", preview: $previewFlag) {
        sys {
          id
        }
        name
        logo {
          url
        }
        iconsCollection {
          items {
            iconImage {
              fileName
              url
            }
          }
        }
        labelsCollection {
          items {
            __typename
            ...resourceSetFields
          }
        }
        clientScopeLogo {
          url
        }
      }
    }
    fragment resourceSetFields on ResourceSet {
      resourcesCollection {
        items {
          ... on Resource {
            key
            value
          }
        }
      }
    }`;

    this.termsConditionsQuery = `query landingPageEntryQuery {
      landingPage(id: "6vbkn20taWq4u33LuIeQ8X") {
        sys {
          publishedVersion
        }
        internalName
        backgroundImage {
          url
        }
        sectionsCollection {
          items {
            ... on ResourceSet {
              resourcesCollection {
                items {
                  ... on Resource {
                    key
                    value
                  }
                }
              }
            }
            ... on Image {
              contentImage {
                url
              }
            }
            ... on RichTextContents {
              contents {
                json
              }
            }
          }
        }
      }
    }`;

    this.sessionTimeOutQuery = `query sessionTimeoutSectionEntryQuery($previewFlag: Boolean!) {
      sessionTimeoutSection(id: "61SpRO5La0RV4nZu1ebP15", preview: $previewFlag) {
        sys {
          id
        }
        image {
          url
        }
        headingText1
        headingText2
        description {
          json
        }
        logOutButtonText
        continueButtonText
      }
    }`;

    this.pageNotFoundQuery = gql`query page404ErrorEntryQuery($previewFlag: Boolean!) {
      page404Error(id: "5aDBDp77JCl95zLo0qwLLW", preview: $previewFlag) {
        sys {
          id
        }
        name
        gcmLogo {
          url
        }
        errorImage {
          url
        }
        emailAddressText
        mailToEmailAddress
        pageText
        pageTitle
        goToDashboardButtonText
      }
    }`;

    this.externalRedirectIconQuery = gql`query externalIconQuery($previewFlag: Boolean!) {
      icon(id: "2hqQwgJHxanhxqlorOJTeE", preview: $previewFlag) {
        key
        iconImage {
          url
        }
      }
    }`;

    this.seriesResourceCollectionQuery = `query resourcesCollection($previewFlag: Boolean!){
      resourceSet(id: "76yNM2wTb6nJDFg7GCQ9y6", preview: $previewFlag) {
          resourcesCollection(preview: false) {
              items {
                  ... on Resource {
                      key
                      value
                  }
                  ... on Icon {
                    key
                    iconImage {
                      url
                    }
                }
              }
          }
      }
    }`;

    this.financialValues = [
      "value",
      "revenue",
      "ebitda",
      "netDebt",
      "netLeverage",
      "riskProfile",
      "marketCap",
      "aquisitionPrice",
      "entryLoanToValue",
      "entryCapRate",
      "exitCapRate",
      "underwrittenDispositionPrice",
      "underwrittenHoldTermValue"
    ]
  }
}
