<div class="main-content mt-16">
    <div *ngIf="iconSet" class="px-4 pb-3 pt-4">
        <div *ngIf="hasErrors; else showDetails" class="ml-3">
            <div *ngIf="showNoAccessError; else showErrorMessage">
                <app-no-access-error></app-no-access-error>
            </div>
            <ng-template #showErrorMessage>
                <div class="mt-3 dashboard-error">
                    <div>
                        <h3 style="text-align: center;">{{errorMessage}}</h3>
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-template #showDetails>
            <div class="container">
                <div class="grid md:grid-cols-12 md:flex min-h-3">
                    <div class="grid-cols-12 md:contents">
                        <div *ngFor="let breadcrumb of breadcrumbs" class="custom-breadcrumb">
                            <a class="custom-breadcrumb__text" routerLinkActive="active" [routerLink]="breadcrumb.route"
                                [queryParams]="breadcrumb.queryParams"
                                [title]="breadcrumb.name" (click)="breadcrumbClick(breadcrumb)"
                                data-cy="breadcrumbText">
                                {{breadcrumb.name}}</a>
                            <mat-icon class="custom-breadcrumb__icon">chevron_right</mat-icon>
                        </div>
                        <app-auto-adjusting-dropdown [selected]="seriesNameForBreadcrumb"
                            [dataForBreadcrumbs]="portfolioDataForBreadcrumb"
                            (goToSelected)="goToSelectedSeries($event)" [queryParams]="queryParamsSeriesId"
                            (sendWidth)="getWidth($event)" [valueProperty]="'displayId'" [nameProperty]="'displayName'"
                            [groupProperty]="'subEntities'" [showOneOptGroup]="true" [type]="'Series'"
                            data-cy="seriesDropdown"></app-auto-adjusting-dropdown>
                    </div>
                </div>
                <ng-container>
                    <app-error-card *ngIf="isPortfolioSeriesDataUnavailable" data-cy="errorCard">
                        <h3 class="card-title">Series data for the selected reporting period <b>{{selectedAsOfDate |
                                dateFormat : 'MM/dd/yyyy'}}</b> is not available.</h3>
                        <div>
                            <div>Please return to the prior page or select a new date from the As of Date Dropdown.
                            </div>
                            <div>Click
                                <a routerLinkActive="active" [routerLink]="breadcrumbs[0]?.route"
                                    (click)="breadcrumbClick(breadcrumbs[0])"
                                    [queryParams]="breadcrumbs[0]?.queryParams"
                                    class="click-here-btn" data-cy="investorDashboardRedirect">
                                    here
                                </a>
                                to go back to the Investor dashboard.
                            </div>
                        </div>
                    </app-error-card>
                    <app-series-dashboard-summary [portfolioSummary]="portfolioSummary" [iconSet]="iconSet"
                        [selectedDateObj]="valReportingDate" [asOfDropdownData]="asOfDropdownData"
                        [selectedAsOfDate]="selectedAsOfDate" [valReportingDate]="valReportingDate"
                        (reportingPeriodChange)="updateAsOfDate($event)" (snapShotBtnClick)="getSnapshot()"
                        [isPortfolioSeriesDataUnavailable]="isPortfolioSeriesDataUnavailable"
                        [seriesSummary]="seriesSummary"></app-series-dashboard-summary>
                    <div class="grid grid-cols-1 lg:grid-cols-2 mt-4">
                        <div class="lg:pr-7 pl-0" tourAnchor="series.dashboard.commitment.widget">
                            <app-commitment-value-widget [widgets]="widgets"
                                [currency]="currency"></app-commitment-value-widget>
                        </div>
                        <div class="pl-0 mt-4 lg:mt-0 lg:ml-0" tourAnchor="series.dashboard.remaining.widget">
                            <app-remaining-value-widget [widgets]="widgets"
                                [currency]="currency"></app-remaining-value-widget>
                        </div>
                    </div>
                    <app-key-performance-widget [widgets]="widgets" [currency]="currency"></app-key-performance-widget>
                    <app-series-holdings-table [iconSet]="iconSet" [investmentTypes]="investmentTypes"
                        [summary]="summary" [portfolioName]="portfolioName" [seriesName]="seriesName"
                        [asOfDate]="selectedAsOfDate" [currency]="currency"></app-series-holdings-table>

                    <app-series-qroll-table [iconSet]="iconSet" [portfolioSummary]="portfolioSummary"
                        [summary]="summary" [investmentTypes]="investmentTypes" [portfolioName]="portfolioName"
                        [seriesName]="seriesName" [asOfDate]="selectedAsOfDate"></app-series-qroll-table>
                </ng-container>
            </div>
        </ng-template>
    </div>
</div>