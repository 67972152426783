<ng-container *ngIf="iconSet">
    <div class="grid grid-cols-2 title-row mt-4">
        <div class="cols-span-7">
            <div class="table-title flex text-md lg:text-2xl">Quarterly Roll Forward
            </div>
            <div class="since-inception-text text-sm lg:text-md" data-cy="qrollTableCurrency">Since Inception |
                Expressed in
                {{portfolioSummary[0]?.currency}} (in millions)</div>
        </div>
        <div class="cols-span-5">
            <div class="text-right">
                <button *ngIf="investmentTypes && investmentTypes?.length > 0;" type="button"
                    (click)="exportQRollDataToCsv($event)" data-cy="exportCsv"
                    class="btn export-csv-btn btn-outline-primary text-xs lg:text-md before:top-2 lg:before:top-3">
                    <mat-icon fontSet="material-icons-outlined" class="export-icon">content_paste_go</mat-icon> 
                    Export to Excel</button>
            </div>
        </div>
    </div>
    <div class="flex flex-row qoq-title-values" *ngIf="portfolioSummary.length != 0">
        <div class="flex-initial w-32">
            <div class="table-tvpi-value text-md lg:text-2xl"
                matTooltip="{{'Previous TVPI: ' + (summary?.priorTvpi | tvpiValueFormat)}}&#13;{{'Current TVPI: ' + (summary?.tvpi | tvpiValueFormat)}}"
                matTooltipClass="blue-tooltip" matTooltipPosition="below" data-cy="summaryTvpiValue">
                {{summary?.qoQTvpiChange | tvpiValueFormat}}
            </div>
            <div class="table-tvpi-text text-sm lg:text-md">QoQ TVPI</div>
        </div>
        <div class="flex-initial w-32">
            <div class="table-irr-value text-md lg:text-2xl"
                matTooltip="{{'Previous IRR: ' + (summary?.priorIrr | irrValueFormat)}}&#13;{{'Current IRR: ' + (summary?.irr | irrValueFormat)}}"
                matTooltipClass="blue-tooltip" matTooltipPosition="below" data-cy="summaryIrrValue">
                {{summary?.qoQIrrChange
                | irrValueFormat}}</div>
            <div class="table-irr-text text-sm lg:text-md">QoQ IRR</div>
        </div>
    </div>
    <ng-container *ngIf="investmentTypes">
        <div class="card q-roll-card bg-white" data-cy="qRollCard"
            *ngIf="investmentTypes && investmentTypes?.length > 0; else noResults">
            <ng-container *ngFor="let investment of QRollData | sortBy:'asc' : 'priority'; let i = index">
                <div class="overflow-x-auto" attr.data-cy="scrollQRTable{{i}}"
                    (scroll)="scrollQRTableHandler($event, investment)">
                    <table class="w-full bg-white mt-6">
                        <thead>
                            <tr class="mx-0 my-3 pb-14 investment-row-head align-top"
                                [ngClass]="{'border-b': !investment.isExpanded}">
                                <th class="investment-type width-18 text-left min-w-170 max-w-170 lg:max-w-full sticky-header"
                                    [ngClass]="investment?.displayShadowOnQRTable ? 'shadow-background' : 'normal-background'"
                                    attr.data-cy="invTypeSummary{{i}}">
                                    <div class="flex">
                                        {{investment.name}}
                                        <app-info-icon tooltipClass="info-icon-top-5" tooltipPosition="right"
                                            tooltipInfo="Totals may not sum due to rounding">
                                        </app-info-icon>
                                    </div>
                                </th>
                                <th class="width-14 min-w-130 max-w-130 lg:max-w-full">
                                    <!-- leave room for space between name and reported values -->
                                </th>
                                <th class="text-right width-9 min-w-100 max-w-100 lg:max-w-full">
                                    <div class="value text-md lg:text-xl" data-cy="qrollBalanceValue">
                                        {{investment.priorNAV |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                    </div>
                                    <div class="inv-period text-sm lg:text-md">Prior Quarter</div>
                                    <div class="value-title text-sm lg:text-md">Net Asset Value</div>
                                </th>
                                <th class="text-right width-12 min-w-100 max-w-100 lg:max-w-full">
                                    <div class="value text-md lg:text-xl" data-cy="qrollBalanceValue">
                                        {{investment.qoQContributions |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                    </div>
                                    <div class="inv-period text-sm lg:text-md">QOQ</div>
                                    <div class="value-title text-sm lg:text-md">Contributions</div>
                                </th>
                                <th class="text-right width-12 min-w-100 max-w-100 lg:max-w-full">
                                    <div class="value text-md lg:text-xl" data-cy="qrollBalanceValue">
                                        {{investment.qoQDistributions |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                    </div>
                                    <div class="inv-period text-sm lg:text-md">QOQ</div>
                                    <div class="value-title text-sm lg:text-md">Distributions</div>
                                </th>
                                <th class="text-right width-12 min-w-100 max-w-100 lg:max-w-full">
                                    <div class="value text-md lg:text-xl" data-cy="qrollBalanceValue">
                                        {{investment.qoQGainLoss |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                    </div>
                                    <div class="inv-period text-sm lg:text-md">QOQ</div>
                                    <div class="value-title text-sm lg:text-md">Investment Gain/Loss</div>
                                </th>
                                <th class="text-right width-9 min-w-100 max-w-100 lg:max-w-full">
                                    <div class="value text-md lg:text-xl" data-cy="qrollBalanceValue">{{investment.nav |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                    </div>
                                    <div class="inv-period text-sm lg:text-md">Current Quarter</div>
                                    <div class="value-title text-sm lg:text-md">Net Asset Value</div>
                                </th>
                                <th class="text-right width-7 min-w-70 max-w-70 lg:max-w-full">
                                    <div class="value text-md lg:text-xl" data-cy="tvpiChange">
                                        {{investment.qoQTvpiChange |
                                        tvpiValueFormat}}</div>
                                    <div class="inv-period text-sm lg:text-md">QOQ</div>
                                    <div class="value-title text-sm lg:text-md">TVPI</div>
                                </th>
                                <th class="text-right width-7 min-w-70 max-w-70 lg:max-w-full">
                                    <div class="value text-md lg:text-xl" data-cy="invIrrChange">
                                        {{investment.qoQIrrChange |
                                        irrValueFormat}}</div>
                                    <div class="inv-period text-sm lg:text-md">QOQ</div>
                                    <div class="value-title text-sm lg:text-md">IRR</div>
                                </th>
                            </tr>
                            <img class="expand-collapse-icon-series mb-[-9px]" attr.data-cy="invTypeIcon{{i}}"
                                (click)="expandTable(investment)"
                                src="{{ investment.isExpanded ? iconSet['icon.series.collapse'] : iconSet['icon.expand']}}">
                        </thead>
                    </table>

                    <ng-container *ngIf="investment.isExpanded">
                        <div class="investment-type-table">

                            <table mat-table matSort [dataSource]="dataSources[investment.priority - 1]"
                                class="mb-3 investment-type-section bg-white" multiTemplateDataRows
                                attr.data-cy="invTypeExpandedView{{i}}">
                                <!-- Beginning Column and Td cell Section -->
                                <!-- Name Column -->
                                <ng-container matColumnDef="name" sticky>
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef
                                        [ngClass]="investment?.displayShadowOnQRTable ? 'shadow-background' : 'normal-background'"
                                        class="width-18 text-left sub-header text-sm lg:text-md min-w-170 max-w-170 lg:max-w-full">
                                        Deal Name </th>
                                    <td mat-cell *matCellDef="let deal"
                                        class="width-18 text-left deal-row text-sm lg:text-md"
                                        [ngClass]="{'deal-name': !deal.showHoldings && !deal.disableDrillThrough, 'shadow-background': investment?.displayShadowOnQRTable, 'normal-background': !investment?.displayShadowOnQRTable}">
                                        <div class="deal-name-div" data-cy="dealNameQRoll">
                                            <a *ngIf="!deal.showHoldings && !deal.disableDrillThrough && deal.holdings.length > 0"
                                                routerLinkActive="active"
                                                [routerLink]=" ['/investor', params['investorId'], 'series' , params['seriesId'], 'holding' , deal.holdings[0].masterId]"
                                                [queryParams]="activatedRoute.snapshot.queryParams"
                                                (click)="activityTracking.navigateToDashboard('Holding', deal, 'series qroll table')">
                                                {{deal.name}}
                                            </a>
                                            <span
                                                *ngIf="deal.showHoldings || deal.disableDrillThrough || deal.holdings.length === 0">
                                                {{deal.name}}
                                            </span>
                                            <img class="expand-collapse-icon-holding"
                                                [ngClass]="{'hidden': !deal.showHoldings}"
                                                (click)="deal.isExpanded = !deal.isExpanded; activityTracking.expandOrCollapseToggled('deal', deal, 'series qroll table', deal.isExpanded)"
                                                src="{{ deal.isExpanded ? iconSet['icon.series.collapse'] : iconSet['icon.expand']}}">
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- Investment Status Column -->
                                <ng-container matColumnDef="realizationType">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef
                                        class="border-right text-left sub-header width-14 text-sm lg:text-md min-w-130 max-w-130 lg:max-w-full">
                                        Investment Status </th>
                                    <td mat-cell *matCellDef="let deal" data-cy="qrollDealInvestmentStatus"
                                        class="border-right text-left deal-row width-14 text-sm lg:text-md">
                                        {{deal.realizationType}}
                                    </td>
                                </ng-container>

                                <!-- Prior Quarter Net Asset Value Column -->
                                <ng-container matColumnDef="priorNAV">
                                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                        class="border-right text-right width-9 sub-header text-sm lg:text-md min-w-100 max-w-100 lg:max-w-full">
                                        Net Asset Value </th>
                                    <td mat-cell *matCellDef="let deal"
                                        class="border-right text-right width-9 deal-row text-sm lg:text-md"
                                        data-cy="qrollDealBalanceValue">
                                        {{deal.priorNAV |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                    </td>
                                </ng-container>

                                <!-- Contributions Column -->
                                <ng-container matColumnDef="qoQContributions">
                                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                        class="text-right width-12 sub-header text-sm lg:text-md min-w-100 max-w-100 lg:max-w-full pr-0 lg:pr-1">
                                        Contributions </th>
                                    <td mat-cell *matCellDef="let deal"
                                        class="text-right width-12 deal-row text-sm lg:text-md"
                                        data-cy="qrollDealBalanceValue">
                                        {{deal.qoQContributions |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}} </td>
                                </ng-container>

                                <!-- Distributions Column -->
                                <ng-container matColumnDef="qoQDistributions">
                                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                        class="text-right width-12 sub-header text-sm lg:text-md min-w-100 max-w-100 lg:max-w-full">
                                        Distributions </th>
                                    <td mat-cell *matCellDef="let deal"
                                        class="text-right width-12 deal-row text-sm lg:text-md"
                                        data-cy="qrollDealBalanceValue">
                                        {{deal.qoQDistributions |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}} </td>
                                </ng-container>

                                <!-- qoQGainLoss Column -->
                                <ng-container matColumnDef="qoQGainLoss">
                                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                        class="border-right text-right width-12 sub-header text-sm lg:text-md min-w-100 max-w-100 lg:max-w-full">
                                        Investment Gain/Loss </th>
                                    <td mat-cell *matCellDef="let deal"
                                        class="border-right text-right width-12 deal-row text-sm lg:text-md"
                                        data-cy="qrollDealBalanceValue">
                                        {{deal.qoQGainLoss |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}} </td>
                                </ng-container>

                                <!-- Current Quarter Net Asset Value Column -->
                                <ng-container matColumnDef="nav">
                                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                        class="border-right text-right width-9 sub-header text-sm lg:text-md min-w-100 max-w-100 lg:max-w-full">
                                        Net Asset Value </th>
                                    <td mat-cell *matCellDef="let deal"
                                        class="border-right text-right width-9 deal-row text-sm lg:text-md"
                                        data-cy="qrollDealBalanceValue"> {{deal.nav |
                                        currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}} </td>
                                </ng-container>

                                <!-- TVPI Column -->
                                <ng-container matColumnDef="qoQTvpiChange">
                                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                        class="text-right width-7 sub-header text-sm lg:text-md min-w-70 max-w-70 lg:max-w-full">
                                        &Delta; TVPI
                                    </th>
                                    <td mat-cell *matCellDef="let deal"
                                        class="text-right width-7 deal-row text-sm lg:text-md" data-cy="dealTvpiChange">
                                        {{deal.qoQTvpiChange |
                                        tvpiValueFormat}} </td>
                                </ng-container>

                                <!-- IRR Column -->
                                <ng-container matColumnDef="qoQIrrChange">
                                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                        class="text-right width-7 sub-header text-sm lg:text-md min-w-70 max-w-70 lg:max-w-full">
                                        &Delta; IRR
                                    </th>
                                    <td mat-cell *matCellDef="let deal"
                                        class="text-right width-7 deal-row text-sm lg:text-md" data-cy="dealIrrChange">
                                        {{deal.qoQIrrChange |
                                        irrValueFormat:true:true}} </td>
                                </ng-container>
                                <!-- Ending Column and Td cell Section -->

                                <!-- Header row first section -->
                                <ng-container matColumnDef="header-row-first-group" sticky>
                                    <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1"
                                        class="header-cell border-left-0 border-right-0"
                                        [ngClass]="investment?.displayShadowOnQRTable ? 'shadow-background' : 'normal-background'">
                                    </th>
                                </ng-container>

                                <!-- Header row second section -->
                                <ng-container matColumnDef="header-row-second-group">
                                    <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1"
                                        class="header-cell border-left-0">
                                    </th>
                                </ng-container>

                                <!-- Header row prior quarter section -->
                                <ng-container matColumnDef="header-row-prior-quarter">
                                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"
                                        class="width-9 header-cell min-w-100"> Prior Quarter</th>
                                </ng-container>

                                <!-- Header row QoQ section -->
                                <ng-container matColumnDef="header-row-qoq-change">
                                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"
                                        class="width-36 header-cell min-w-300"> QoQ Change</th>
                                </ng-container>

                                <!-- Header row current quarter section -->
                                <ng-container matColumnDef="header-row-current-quarter">
                                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"
                                        class="width-9 header-cell min-w-100"> Current Quarter
                                    </th>
                                </ng-container>

                                <!-- Header row QoQ change performance section -->
                                <ng-container matColumnDef="header-row-qoq-change-performance">
                                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="2"
                                        class="width-14 header-cell border-right-0 min-w-140"> QoQ Change</th>
                                </ng-container>

                                <!-- Beginning Expanded Rows -->
                                <ng-container matColumnDef="expandedDetail">
                                    <td *matCellDef="let deal" [attr.colspan]="12" class="p-0">
                                        <div [@detailExpand]="deal.isExpanded ? 'expanded' : 'collapsed'">
                                            <div *ngIf="deal.isExpanded">
                                                <table mat-table [dataSource]="deal.holdings" class="qroll-table">
                                                    <!-- Name Column -->
                                                    <ng-container matColumnDef="holdingName" sticky>
                                                        <td mat-cell
                                                            *matCellDef="let holding; let i = index; let first = first"
                                                            [ngClass]="{'holding-name': !holding.disableDrillThrough, 'shadow-background':investmentType?.displayShadowOnTable, 'normal-background':!investmentType?.displayShadowOnTable, 'border-top-0': first}"
                                                            class="min-w-170 max-w-170 lg:max-w-full width-18 text-left holding-row text-sm lg:text-md">
                                                            <a *ngIf="!holding.disableDrillThrough"
                                                                routerLinkActive="active"
                                                                [routerLink]=" ['/investor', params['investorId'], 'series' , params['seriesId'], 'holding' , holding.masterId]"
                                                                [queryParams]="queryParams"
                                                                data-cy="holdingsRedirectLink"
                                                                (click)="activityTracking.navigateToDashboard('Holding', holding, 'series qroll table')">
                                                                {{holding.name}}
                                                            </a>
                                                            <span *ngIf="holding.disableDrillThrough"
                                                                data-cy="holdingsRedirectLinkDisabled">
                                                                {{holding.name}}
                                                            </span>
                                                        </td>
                                                    </ng-container>

                                                    <!-- Investment Status Column -->
                                                    <ng-container matColumnDef="realizationType">
                                                        <td mat-cell data-cy="qrollHoldingInvestmentStatus"
                                                            *matCellDef="let holding; let i = index; let first = first"
                                                            class="border-right text-left holding-row width-14 text-sm lg:text-md min-w-130 max-w-130 lg:max-w-full">
                                                            {{holding.status}}
                                                        </td>
                                                    </ng-container>

                                                    <!-- Prior Quarter Net Asset Value Column -->
                                                    <ng-container matColumnDef="holdingPriorNAV">
                                                        <td mat-cell
                                                            *matCellDef="let holding; let i = index; let first = first"
                                                            class="border-right text-right width-9 holding-row min-w-100 text-sm lg:text-md"
                                                            [ngClass]="{'border-top-0': first}"
                                                            data-cy="qrollHoldingBalanceValue">
                                                            {{holding.priorNAV |
                                                            currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                                        </td>
                                                    </ng-container>

                                                    <!-- Contributions Column -->
                                                    <ng-container matColumnDef="holdingQoQContributions">
                                                        <td mat-cell
                                                            *matCellDef="let holding; let i = index; let first = first"
                                                            [ngClass]="{'border-top-0': first}"
                                                            class="text-right width-12 holding-row min-w-100 text-sm lg:text-md"
                                                            data-cy="qrollHoldingBalanceValue">
                                                            {{holding.qoQContributions |
                                                            currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                                        </td>
                                                    </ng-container>

                                                    <!-- Distributions Column -->
                                                    <ng-container matColumnDef="holdingQoQDistributions">
                                                        <td mat-cell
                                                            *matCellDef="let holding; let i = index; let first = first"
                                                            [ngClass]="{'border-top-0': first}"
                                                            class="text-right width-12 holding-row min-w-100 text-sm lg:text-md"
                                                            data-cy="qrollHoldingBalanceValue">
                                                            {{holding.qoQDistributions |
                                                            currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                                        </td>
                                                    </ng-container>

                                                    <!-- qoQGainLoss Column -->
                                                    <ng-container matColumnDef="holdingQoQGainLoss">
                                                        <td mat-cell
                                                            *matCellDef="let holding; let i = index; let first = first"
                                                            [ngClass]="{'border-top-0': first}"
                                                            class="border-right text-right width-12 holding-row min-w-100 text-sm lg:text-md"
                                                            data-cy="qrollHoldingBalanceValue">
                                                            {{holding.qoQGainLoss |
                                                            currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                                        </td>
                                                    </ng-container>

                                                    <!-- Current Quarter Net Asset Value Column -->
                                                    <ng-container matColumnDef="holdingNav">
                                                        <td mat-cell
                                                            *matCellDef="let holding; let i = index; let first = first"
                                                            [ngClass]="{'border-top-0': first}"
                                                            class="border-right text-right width-9 holding-row min-w-100 text-sm lg:text-md"
                                                            data-cy="qrollHoldingBalanceValue"> {{holding.nav |
                                                            currencyFormat:portfolioSummary[0]?.currency:'Millions':true:true}}
                                                        </td>
                                                    </ng-container>

                                                    <!-- TVPI Column -->
                                                    <ng-container matColumnDef="holdingQoQTvpiChange">
                                                        <td mat-cell
                                                            *matCellDef="let holding; let i = index; let first = first"
                                                            [ngClass]="{'border-top-0': first}"
                                                            class="text-right width-7 holding-row min-w-70 text-sm lg:text-md"
                                                            data-cy="holdingTvpiChange">
                                                            {{holding.qoQTvpiChange |
                                                            tvpiValueFormat}} </td>
                                                    </ng-container>

                                                    <!-- IRR Column -->
                                                    <ng-container matColumnDef="holdingQoQIrrChange">
                                                        <td mat-cell
                                                            *matCellDef="let holding; let i = index; let first = first"
                                                            [ngClass]="{'border-top-0': first}"
                                                            class="text-right width-7 holding-row min-w-70 text-sm lg:text-md"
                                                            data-cy="holdingIrrChange">
                                                            {{holding.qoQIrrChange |
                                                            irrValueFormat:true:true}} </td>
                                                    </ng-container>

                                                    <tr mat-row
                                                        *matRowDef="let holding; columns: innerDisplayedColumns;"
                                                        attr.data-cy="qrollHoldingRow{{holding.masterId}}">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <!-- Ending Expanded Rows -->

                                <tr mat-header-row
                                    *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-prior-quarter','header-row-qoq-change','header-row-current-quarter','header-row-qoq-change-performance']; sticky: true">
                                </tr>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let deal; columns: displayedColumns;"
                                    tourAnchor="series.dashboard.qroll" attr.data-cy="qrollDealRow{{deal.masterId}}">
                                </tr>
                                <tr mat-row *matRowDef="let deal; columns: ['expandedDetail']"
                                    [ngClass]="{'hidden': !deal.isExpanded}"></tr>
                            </table>
                        </div>

                    </ng-container>
                </div>
            </ng-container>
        </div>

        <ng-template #noResults>
            <div class="w-full series-table-error" data-cy="noResults">
                <div class="row">
                    <div class="col-12">
                        <div class=" mt-3">
                            <div>
                                <p class="text-center error-message">No Results</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-container>