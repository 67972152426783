import { Component, Input, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router, RouterModule, RouterOutlet } from '@angular/router';
import { ProxyService } from 'src/app/services/proxy.service';
import { UserService } from 'src/app/services/user.service';
import { EnvironmentVariablesService } from 'src/app/services/environment-variables.service';
import { CommonModule } from '@angular/common';
import { InvestorDropdownComponent } from '../investor-dropdown/investor-dropdown.component';
import { GcmSidenavComponent, NavItem } from '@gcm/gcm-ui-angular';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [CommonModule, InvestorDropdownComponent, GcmSidenavComponent, MatSidenavModule, RouterOutlet, RouterModule],
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Input() showMobileSideNav: boolean = false;
  currentPage;
  navigationItems: NavItem[] = [];
  displayName: string = '';
  opened: boolean = false;
  routerLinkActiveOptions: IsActiveMatchOptions = { paths: 'subset', matrixParams: 'ignored', queryParams: 'ignored', fragment: 'ignored' };

  constructor(
    public router: Router,
    public proxyService: ProxyService,
    private userService: UserService,
    public evService: EnvironmentVariablesService,
  ) {}

  ngOnInit(): void {
    this.loadNavigationItems();
    this.displayName = this.userService.getUserFullName();
  }

  loadNavigationItems(): void {
    this.navigationItems = [];
    this.addToNavigation('Dashboard', 'investor', 'dashboard', false);
    this.addToNavigation('Contact', 'contact', 'contacts_product', false);
    if (!this.userService.isExternalUser() && !this.evService.productionURL.includes('demo')) {
      this.addToNavigation('Document Review', 'document-review', 'assignment', false);
    }
    if (this.userService.getApplicationAccess().indexOf('ClientDocument') > -1) {
      // check if user has access to client docs, if yes then show client docs as docs in nav
      this.addToNavigation('Documents', this.evService.clientDocs, 'description', true);
    } else if (this.userService.getApplicationAccess().indexOf('VISTAONE') > -1) {
      // check if user has access to vista one, if yes then show vista one as docs in nav
      this.addToNavigation('Documents - VistaOne', this.evService.externalUris['vistaOne'], 'description', true)
    }
    if (this.userService.getApplicationAccess().indexOf('PpaReportPortfolio') > -1) {
      this.addToNavigation('ARS', this.evService.arsUri, null, true, 'https://images.ctfassets.net/hhmmh7cybzrp/6JgmwZg5uNO1jzR1oxDwce/329d5202d975f805072e5c5c276acfb6/fundsARS_B.png')
    }
    if (this.userService.doesUserHaveSEIAccess()) {
      this.addToNavigation('Documents - SEI', this.evService.externalUris['sei'], 'description', true)
    }
  }

  addToNavigation(name: string, url: string, icon: string, isExternalUrl: boolean, iconImg?: string) {
    this.navigationItems.push({
      label: name,
      name,
      url,
      externalUrl: isExternalUrl,
      icon,
      iconImg
    });
  }
}