<div class="row mt-4 summary grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-0" *ngIf="iconSet">
    <div class="col-span-6 lg:col-span-7 pl-0 lg:pr-[30px]" tourAnchor="holding.dashboard.summary"
        data-cy="holdingSummary">
        <div class="holding-summary widget-card card bg-white">
            <div class="header-section">
                <div class="flex flex-wrap justify-between">
                    <h4 class="summary-text pl-0">Summary</h4>
                    <div class="currency-text" data-cy="holdingCurrencyText">Holding Currency:
                        {{holdingSummary?.currency ?? '-'}}
                        {{holdingSummary?.currency | currencySymbol:true}}</div>
                </div>
                <div class="summary-desc pt-3 md:min-h-24">
                    <div *ngIf="showSummary; else noSummary">
                        <div [innerHTML]="this.holdingSummaryText | safeHtml" #summaryElement
                            class="short-desc text-md lg:text-lg lg:min-h-24" data-cy="summary">
                        </div>
                        <div *ngIf="showMoreBtn">
                            <a href="javaScript:;" class="view-more-btn" (click)="openModal()"
                                data-cy="viewMoreButton">View More</a>
                        </div>
                    </div>
                    <ng-template #noSummary>
                        <div class="text-md lg:text-lg">
                            No summary available
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 pt-10 gap-x-10 w-full">
                <div class="summary-details grid grid grid-cols-2 gap-y-10 md:gap-y-24 md:mr-4 lg:mr-0">
                    <div class="pl-0">
                        <div class="value text-md lg:text-lg" data-cy="investmentStrategyText">
                            {{holdingSummary?.strategy}}</div>
                        <div class="text-md lg:text-lg">Investment Strategy</div>
                    </div>
                    <div class="pl-0">
                        <div class="value text-md lg:text-lg text-right" data-cy="fundSizeValue">
                            {{holdingSummary?.fundSize |
                            currencyFormat:holdingSummary?.currency:'Millions'}}
                        </div>
                        <div class="text-right text-md lg:text-lg">Fund Size</div>
                    </div>
                    <div class="pl-0">
                        <div class="value text-md lg:text-lg" data-cy="vintageYearText">{{holdingSummary?.vintageYear}}
                        </div>
                        <div class="text-md lg:text-lg">Vintage Year</div>
                    </div>
                </div>
                <div class="manager md:mt-[9.5em]">
                    <div class="manager-logo" *ngIf="cfmanagerLogo !== ''">
                        <img src="{{cfmanagerLogo}}" class="md:max-w-[175px] lg:max-w-[240px]"
                            data-cy="investmentManagerLogo">
                    </div>
                    <div class="manager-details">
                        <div class="manager-name text-md md:text-lg" data-cy="investmentManagerText">
                            {{holdingSummary?.investmentManagerName}}</div>
                        <div class="text-md md:text-lg">Manager</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-span-6 lg:col-span-5"
        tourAnchor="holding.dashboard.series.summary">
        <div class="series-summary widget-card card bg-white min-h-[325px] lg:min-h-[410px]">
            <div class="header-section row grid grid-cols-2" *ngIf="!noResultsPortfolioSeriesDetails" data-cy="portfolioSeriesDetails">
                <div class="col-7">
                    <div class="portfolio-name" data-cy="portfolioName">{{holdingSummary?.portfolioName}}</div>
                </div>
                <div class="col-5">
                    <div class="inv-title text-right">Investment Status </div>
                    <div class="inv-detail text-right" data-cy="investmentStatus">{{holdingSummary?.realizationType}}
                    </div>
                </div>
                <div class="col-7">
                    <div class="series-name" data-cy="seriesName">{{holdingSummary?.portfolioSeriesName}}</div>
                </div>
                <div class="col-5">
                    <div class="inv-title text-right">Investment Type </div>
                    <div class="inv-detail text-right mb-2.5" data-cy="investmentType">
                        {{holdingSummary?.investmentType}}</div>
                </div>
            </div>
            <div class="header-section row no-result-text" *ngIf="noResultsPortfolioSeriesDetails"
                data-cy="portfolioSeriesDetailsNoResults">
                {{error}}
            </div>
            <div class="series-details grid grid-cols-2 gap-y-10 md:gap-y-14 lg:gap-y-20 gap-x-[30px] pt-5 md:px-[15px] md:gap-x-20 xl:ml-14"
                *ngIf="!noResultsSeriesDetails" data-cy="seriesDetails">
                <div class="value-set md:max-w-[200px]">
                    <div class="value-icon">
                        <img src="{{iconSet['icon.commitment']}}">
                    </div>
                    <div class="value-container">
                        <div class="value text-right text-lg lg:text-2xl" data-cy="balanceValue">
                            {{holdingSummary?.commitment |
                            currencyFormat:holdingSummary?.portfolioCurrency:'Millions'}}</div>
                        <div class="text-right text-md lg:text-lg">Commitments</div>
                    </div>
                </div>
                <div class="value-set md:max-w-[200px]">
                    <div class="value-icon">
                        <img src="{{iconSet['icon.investor.contributions']}}">
                    </div>
                    <div class="value-container">
                        <div class="value text-right text-lg lg:text-2xl" data-cy="balanceValue">
                            {{holdingSummary?.contributions |
                            currencyFormat:holdingSummary?.portfolioCurrency:'Millions'}}</div>
                        <div class="text-right text-md lg:text-lg">Contributions</div>
                    </div>
                </div>
                <div class="value-set md:max-w-[200px]">
                    <div class="value-icon">
                        <img src="{{iconSet['icon.investor.distributions']}}">
                    </div>
                    <div class="value-container">
                        <div class="value text-right text-lg lg:text-2xl" data-cy="balanceValue">
                            {{holdingSummary?.distributions |
                            currencyFormat:holdingSummary?.portfolioCurrency:'Millions'}}</div>
                        <div class="text-right text-md lg:text-lg">Distributions</div>
                    </div>
                </div>
                <div class="value-set md:max-w-[200px]">
                    <div class="value-icon">
                        <img src="{{iconSet['icon.investor.nav']}}">
                    </div>
                    <div class="value-container">
                        <div class="value text-right text-lg lg:text-2xl" data-cy="balanceValue">{{holdingSummary?.nav |
                            currencyFormat:holdingSummary?.portfolioCurrency:'Millions'}}
                        </div>
                        <div class="text-right text-md lg:text-lg">NAV</div>
                    </div>
                </div>
                <div class="value-set md:max-w-[200px]">
                    <div class="value-icon">
                        <img src="{{iconSet['icon.tvpi']}}">
                    </div>
                    <div class="value-container">
                        <div class="value text-right text-lg lg:text-2xl" data-cy="tvpiValue">{{holdingSummary?.tvpi |
                            tvpiValueFormat}}
                        </div>
                        <div class="text-right text-md lg:text-lg">Investment TVPI</div>
                    </div>
                </div>
                <div class="value-set md:max-w-[200px]">
                    <div class="value-icon">
                        <img src="{{iconSet['icon.irr']}}">
                    </div>
                    <div class="value-container">
                        <div class="value text-right text-lg lg:text-2xl" data-cy="irrValue">{{holdingSummary?.irr |
                            irrValueFormat}}</div>
                        <div class="text-right text-md lg:text-lg">Investment IRR</div>
                    </div>
                </div>
            </div>
            <div class="series-details no-result-text" *ngIf="noResultsSeriesDetails" data-cy="seriesDetailsNoResults">
                {{error}}
            </div>
        </div>
    </div>
</div>