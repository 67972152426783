export const environment = {
  production: true,
  productionURL: "%%productionURL%%", // Azure endpoint/cnd 
  sessionStandAloneTime: "%%sessionStandAloneTime%%",
  loggingTargets: "%%loggingTargets%%",
  oktaConfigs: "%%oktaConfigs%%",
  contentful: "%%contentful%%",
  googleMapsApiKey: "%%googleMapsApiKey%%",
  googleAnalyticsID: "%%googleAnalyticsID%%",
  raygunApiKey: "%%raygunApiKey%%",
  proxy: "%%proxy%%",
  clientExperienceDataServiceUri: "%%clientExperienceDataServiceUri%%",
  clientDocumentDownloadUri: "%%clientDocumentDownloadUri%%",
  holdingDateForHidingWidgets: "%%holdingDateForHidingWidgets%%",
  notificationConfig: "%%notificationConfig%%",
  clientDocs: "%%clientDocs%%",
  hideDatesPriorToDate: "%%hideDatesPriorToDate%%",
  assetsFolderPath: "%%assetsFolderPath%%",
  externalUris: "%%externalUris%%",
  ars: "%%ars%%",
  appName: "%%appName%%",
  notfound: "%%notfound%%"
};
